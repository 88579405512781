import React from 'react'
import Jens from '../../images/profilepictures/JensRound.png'
import Christian from '../../images/profilepictures/Christian.png'
import Valentin from '../../images/profilepictures/ValluRound.png'


import {
  StaffContainer,
  StaffH1,
  StaffP,
  StaffPBox,
  StaffPic,
  StaffWrapper,
  StaffPHeading,
  StaffPtext,
  ContactInfoWrapper,
  ContactInfoBox,
  ContactInfo,
  EmailContact
} from './StaffElements';


function Staff() {
  return (
    <StaffContainer id ='staff'>
        <StaffH1>Course Staff and Contacts</StaffH1>
        <StaffWrapper>
          <StaffPBox>
            <StaffPic>
              <img src={Jens} width='100%' alt='Jens'/>
            </StaffPic>
            <StaffP>
            <StaffPHeading>
                <b>Jens Schmidt</b> - Professor of Strategic Management, Course Teacher
                </StaffPHeading>
              <StaffPtext>
              Jens Schmidt is a strategy professor currently working as the head of the department of IEM in Aalto. 
              </StaffPtext>
            </StaffP>
          </StaffPBox>

          <StaffPBox>
            <StaffPic>
            <img src={Christian} width='100%' alt='Christian'/>
            </StaffPic>
            <StaffP>
            <StaffPHeading>
                <b>Christian Langen</b> - Course Teacher <br/> 
                </StaffPHeading>
              <StaffPtext>
              Christian Langen has been in leadership roles in the global renewable energy industry for 20 years.
              </StaffPtext>
            </StaffP>
          </StaffPBox>

          <StaffPBox>
            <StaffPic>
            <img src={Valentin} width='100%' alt='Valentin'/>
            </StaffPic>
            <StaffP>
              <StaffPHeading>
                <b>Valentin Jutila</b> - Course assistant
                </StaffPHeading>
              <StaffPtext>
              Valentin Jutila is a student of Information Networks at Aalto University, with a keen interest in green tech.
              </StaffPtext>
            </StaffP>
          </StaffPBox>

          <ContactInfoWrapper>
            <ContactInfoBox>
              <ContactInfo>
                For contact and inquiries, please use the following email address:
              </ContactInfo>
              <EmailContact>
              energy-linkage [at] aalto.fi
              </EmailContact>
            </ContactInfoBox>
          </ContactInfoWrapper>

          </StaffWrapper>
    </StaffContainer>
  )
}

export default Staff