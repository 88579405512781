import styled from 'styled-components'
import MobileBackground from '../../images/BoltMobileBG.svg'
import Background from '../../images/BoltBG.svg'

export const StaffContainer = styled.div`

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background-color: #fc7839;
  padding-top: 3%;
  padding-bottom: 8%;
  background-image: url(${Background});

  @media screen and (max-width: 480px) {
    background-image: url(${MobileBackground});
    padding-top: 5%;
    
  }
`

export const StaffWrapper = styled.div`
  max-width: 1000px;
  width: 90%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
`

export const StaffH1 = styled.h1`
  color: #fff;
  font-size: 4rem;
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 0px 20px;

  @media screen and (max-width: 700px) {
    font-size: 2.5rem;
  }
  @media screen and (max-width: 480px) {
    margin-bottom: 20px;
  }
`

export const StaffPBox = styled.div`
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 10px;
  z-index: 3;

  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`

export const StaffPic = styled.div`
  width: 35%;
  padding-left: 15%;

  @media screen and (max-width: 900px) {
    padding-left: 0;
  }

  @media screen and (max-width: 480px) {
    width: 50%;
  }
`

export const StaffP = styled.div`
  padding-top: 30px;
  margin-left: 40px;
  margin-right: 80px;
  color: #000;
  font-size: 20px;
  text-align: left;
  max-width: 700px;
  border-radius: 10px;
  max-width: 50%;
  background-color: rgba(256, 256, 256, 0.8);

  @media screen and (max-width: 900px) {
    max-width: 80%;
    margin-top: 20px;
    margin-right: 40px;
    margin-left: 40px;
    font-size: 16px;
  }

  @media screen and (max-width: 480px) {
    margin-right: 20px;
    margin-left: 20px;
    font-size: 14px;
    max-width: 100%;
    margin-top: 10px;
    padding-top: 20px;
  }
`

export const StaffPHeading = styled.p`
  padding-left: 20px;
`

export const StaffPtext = styled.p`
  padding-left: 20px;
  margin-top: 10px;
  padding-bottom: 30px;
  color: #5C5C5C;
  width: 95%;
  font-size: 16px;
  font-family: 'Encode Sans Expanded', italic;

  @media screen and (max-width: 480px) {
    font-size: 14px;
    max-width: 100%;
    padding-bottom: 20px;
    margin-bottom: 10px;
  }
`;

export const ContactInfoWrapper = styled.div`
display: flex;
align-items: center;
justify-content: center;
`;

export const ContactInfoBox = styled.div`
display: flex;
flex-direction: column;
padding: 5%;
margin-top: 4%;
border-radius: 10px;
background-color: rgba(255,255,255,0.95);
max-width: 90%;

@media screen and (max-width: 450px){
  margin-top: 12%;
  margin-bottom: 10%;
}


`;

export const ContactInfo = styled.p`
font-size: 1.3rem;
text-align: center;

@media screen and (max-width: 450px){
  font-size: 1rem;
}
`;

export const EmailContact = styled.h2`
text-align: center;
color: #fc7839;
padding-top: 2%;

@media screen and (max-width: 450px){
  padding-top: 5%;
  font-size: 1.2rem;
}
`;

