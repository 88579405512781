import styled from 'styled-components';

export const ApplicationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fc7839;
  padding-bottom: 12%;

  @media screen and (max-width: 1000px) {
    height: auto;
    padding-top: 3vh;
    padding-bottom: 6vh;
  }

`;

export const ApplicationWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 16px;
  padding: 0 50px;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
`;

export const ApplicationCard = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  max-height: 340px;
  padding: 30px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
  }
`;

export const ApplicationIcon = styled.img`
  height: 160px;
  width: 160px;
  margin-bottom: 10px;
`;

export const ApplicationH1 = styled.h1`
  font-size: 3.5rem;
  color: #fff;
  box-sizing: border-box;
  padding: 10vh 2vh 10vh 2vh;
  

  @media screen and (max-width: 700px) {
    padding: 12% 5% 13% 5%;
    font-size: 2.3rem;
    text-align: center;
  }
  @media screen and (max-width: 480px){
    
  }
`;

export const ApplicationH2 = styled.h2`
  font-size: 1rem;
  margin-bottom: 10px;
`;

export const ApplicationP = styled.p`
  font-size: 1rem;
  text-align: center;
`;

export const InfoWrapper = styled.div`
  padding: 4% 0 0 0;
  width: 90%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ApplicationDetails = styled.div`

`;

export const ApplicationEmailWrap = styled.div`
background-color: #fff;
padding: 5%;
border-radius: 10px;
margin-top: 3%;

@media screen and (max-width: 768px){
  margin-top: 8%;
  padding: 7% 5% 7% 5%;
}
`;

export const ApplicationEmailText = styled.p`
text-align: center;
color: #153245;
font-size: 1.3rem;

@media screen and (max-width: 768px){
  font-size: 1.1rem;
}
`;

export const ApplicationEmailAddress = styled.h3`
text-align: center;
padding: 2% 0 0 0;
color: #fc7839;
font-size: 1.5rem;

@media screen and (max-width: 768px){
  font-size: 1.3rem;
}
`;

export const ApplicationH3 = styled.h3`
  color: #fff;
  font-size: 1.5rem;
  padding: 2% 0% 2% 0%;
  text-align: center;
@media screen and (max-width: 768px){
  font-size: 1.3rem;
padding: 12% 0% 8% 0%;
}
`;

export const ApplicationDesc = styled.p`
  max-width: 600px;
  font-size: 1.2rem;
  color: #fff;
  padding: 5% 0% 0% 0%;
  @media screen and (max-width: 768px){
  font-size: 1rem;
padding: 2% 5% 2% 5%;
}
`;
