import React from "react";

import {
  AboutContainer,
  AboutH1,
  AboutWrapper,
  AboutCard,
  AboutP,
  TextBoxL,
  TextBoxR
} from "./AboutElements";

const About = () => {
  return (
    <AboutContainer id="about">
      <AboutH1>About the Course</AboutH1>
      <AboutWrapper>
        <AboutCard>
          <TextBoxL>
            <AboutP>
              Take the opportunity to deepen your knowledge about innovation and the commercialization of new technologies and business models in the energy field. 
              Learn more about energy as a connected system in fundamental transition:
              <br/><br/>
              <li>View the energy world from a systemic innovation perspective</li>
              <li>Learn about the transformation of industrial energy supply and the strategies to solve the triangle of cost, sustainability and reliability</li>
              <li>Experience innovations in making mobility sustainable, from charging infrastructure, storage to sustainable aviation fuels.</li>
              <li>Understand the fundamental shift in electricity production from the perspective of grid operators, solar and wind power developers, bioenergy providers, legacy power producers and power markets</li>
              <li>Discuss how energy changes from a user perspective and which opportunities for innovation exist</li>
              <br/>
              As a course participant, you will gain a better understanding of the rapidly changing, innovation-driven energy world! 
              </AboutP>
          </TextBoxL>
        </AboutCard>

        <AboutCard>
          <TextBoxR>
            <AboutP>
            Energy Linkage is designed to serve as a community building platform, linking together students from diverse backgrounds, who share the same interests and aspirations, with professionals from every corner of the energy industry.
              <br />
              <br />
              By attending the guest lectures and excursions, you will get to know companies, organizations and individuals from the field. The course offers an unique opportunity to get to know and talk one on one with key players in the field.
              <br />
              <br />
              The course is open to all master’s level students from Aalto
              University and University of Helsinki, worth 10 ECTS credits, and limited to 16 participants. The course is set to run from mid-January to mid-April.
            </AboutP>
          </TextBoxR>
        </AboutCard>

        <AboutCard>
          <TextBoxL>
            <AboutP>
              The course consists of weekly lectures, frequently held by
              professionals from the industry, and two innovation challenges.
              <br />
              <br />
              In the innovation challenges solutions are built in multidiscipinary teams formed from
              the course participants. The teams will
              finally pitch their solutions in front of a jury, with the best
              solutions being awarded.
              <br />
              <br />
              <b>This course is a highly intense but rewarding experience!</b>
            </AboutP>
          </TextBoxL>
        </AboutCard>

      </AboutWrapper>
    </AboutContainer>
  );
};

export default About;
