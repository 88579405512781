import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  z-index: 0;
  //overflow: hidden;
  background-color: #FC7839;
  
  @media screen and (max-width: 450px){
  padding-bottom: 5%;
}
`;

export const AccessibilityWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Icon = styled(Link)`
  margin-left: 32px;
  margin-top: 32px;
  max-width: 300px;

  @media screen and (max-width: 480px) {
    margin-left: 16px;
    margin-top: 8px;
  }
`;

export const HTLogo = styled.img`
width: 100%;

@media screen and (max-width: 450px){
  width: 80%;
  padding-top: 5%;
}
`;


export const Info = styled.div`
  max-width: 1000px;
  height: auto;
  width: 90%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  padding-top: 4%;
`;

export const InfoH1 = styled.h1`
  color: #fff;
  font-size: 2rem;
  text-align: left;

  @media screen and (max-width: 450px){
  font-size: 1.5rem;
  padding-top: 5%;
}
`;

export const InfoH2 = styled.h2`
  color: #fff;
  
  @media screen and (max-width: 450px){
  font-size: 1.3rem;
  padding-top: 5%;
}
`;

export const Text = styled.span`
  text-align: left;
  margin: 1% 10% 4% 0;
  color: #fff;
  font-size: 1rem;
`;
