
import Ada from '../../images/alumniImages/Ada.png'
import Solvi from '../../images/alumniImages/Solvi.png'
import Kim from '../../images/alumniImages/Kim.png'

export const SliderData = [
  
  {
    image: Kim,
    alt: 'Kim',
    quote: 'Ever since I heard about HealthTech Linkage during my Bachelor´s in 2022, an idea had taken place in my mind — the idea that this is the holy grail of courses and that I must complete it by all means necessary. I was sick of learning theory instead of practice, fed up with being a student of the MedTech industry without knowledge about it. Therefore, I could not help but feel uttermost triumph, when I received my acceptance email.',
    alumniname: 'Kim Valén, Biomedical Engineering MSc - HealthTech Linkage 2023 Alumni'
      
  },

    {
      image: Ada,
      alt: 'Ada',
      quote: 'HealthTech Linkage was a great choice to acquire an entrepreneurial, more strategic view on the healthcare sector. It was also an amazing opportunity to interact directly with innovative companies and to build an international and multidisciplinary network with other students.',
      alumniname: 'Ada Junquera Mencía, TRANSMED MSc - HealthTech Linkage 2022 Alumni'
        
  },

  {
    image: Solvi,
    alt: 'Solvi',
    quote: 'Not only did my fellow students teach me a lot, but also HealthTech entrepreneurs who came as guest lecturers and shared their journey with us. The course organizers were ambitious and creative which made this course one of the most memorable experiences in my Master`s studies.',
    alumniname: 'Sölvi Rögnvaldsson, Life Science Informatics MSc - HealthTech Linkage 2022 Alumni'
      
}
      
    
    
  ];