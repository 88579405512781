import React, { useState } from 'react'
import { SliderData } from './SliderData';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';

import {
  AlumniContainer,
  AlumniContent,
  AlumniH1,
  AlumniP,
  AlumniPBox,
  AlumniH2,
  AlumniContentWrapper,
  QuoteSlider,
  QuoteContentWrapper,
  ScrollLeft,
  ScrollRight,
  QuoteDescription,
  AlumniName
} from './AlumniElements';


const Alumni = ({slides, quote, alumniname }) => {
  const [current, setCurrent] = useState(0);
  const length = slides.length;

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  if (!Array.isArray(slides) || slides.length <= 0) {
    return null;
  }


  return (
    <AlumniContainer id ='alumni'>
      <AlumniContent>
        <AlumniH1>The Concept</AlumniH1>
        <AlumniContentWrapper className='Alumniwrapper'>
          <AlumniPBox>
            <AlumniH2>Energy Linkage is the new addition to the linkage courses</AlumniH2>
            <AlumniP>
                The very popular course HealthTech Linkage is running since two years now. This upcoming course 
                will be based on the same tried and trusted concept, only changing the focus and content from the health technology field to the energy field.
                <br/>
                
            </AlumniP>
            <AlumniP>
              Diversity of the participants is a key aspect of the course. So far HealthTech Linkage has attracted participants from 14 different majors in Aalto University and University of Helsinki.
            </AlumniP>
            <AlumniP>
            The HealthTech Linkage students met people who shared insights from the following companies, among others: 
                Apple, Oura, Orion, Terveystalo, Planmeca, Bayer, Nordic Healthcare Group, Veri, and Blueprint Genetics.
            </AlumniP>
            <AlumniP>
              HealthTech Linkage has set  the bar high, and we are keen on making Energy Linkage as big of a success story as HealthTech Linkage is!
            </AlumniP>
            <AlumniH2>Don't just take our word for it, read what the alumni had to say about the course!</AlumniH2>
          </AlumniPBox>
            
            

            <QuoteSlider>
      <ScrollLeft> <FaAngleLeft onClick={prevSlide} /> </ScrollLeft>
      <ScrollRight> <FaAngleRight onClick={nextSlide} /> </ScrollRight>
      {SliderData.map((slide, index) => {
        return (
          <QuoteContentWrapper
            className={index === current ? 'slide active' : 'slide'}
            key={index}
          > 
            {index === current && (
              <img src={slide.image} alt={slide.alt} className='image' />
              
            )}
            <QuoteDescription className="quote">{
            index === current && (slide.quote)}</QuoteDescription>
            <AlumniName className="name">{index === current && (slide.alumniname)}</AlumniName>
          </QuoteContentWrapper>
        );
      })}
    </QuoteSlider>

          
          </AlumniContentWrapper>
      </AlumniContent>
    </AlumniContainer>
  )
}

export default Alumni;