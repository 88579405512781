import styled from 'styled-components';


//Bottom layer, background
export const HeroContainer = styled.div`
  background: #fff;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  z-index: 1;
  position: relative;
  
  @media screen and (max-width: 480px){
    height: calc(100vh - 100px);
    margin-top: 100px;
  }
`;


export const HeroContent = styled.div`
  z-index: 3;
  width: 90%;
  max-width: 1000px;
  margin-top: 80px;
  padding-top: 5%;
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  
  @media screen and (max-width: 768px) {
    margin-top: 0;
    align-items: center;
    width: 100%;
    padding: 0px;
  }
`;

export const HeroRow = styled.div`
display: grid;
margin-top: 2%;
grid-auto-columns: minmax(auto, 1fr);
align-items: center;
justify-content: center;
grid-template-areas: 'col2 col1';
@media screen and (max-width: 850px) {
  margin-top: 0;
    grid-template-areas: 'col1' 'col2';
}
`;

export const Column1 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col1;
  justify-content: center;
  align-items: center;
`;

export const Column2 = styled.div`
  margin-bottom: 15px;
  grid-area: col2;
  justify-content: center;
  align-items: center;
  

  @media screen and (max-width: 480px){
    margin-bottom: 10px;
    margin-top: -10px;
  }
`;

export const MobileH1 = styled.h1`
display: none;
text-align: center;

@media screen and (max-width: 768px){
  display: flex;
  justify-content: center;
  padding-top: 10%;
  padding-bottom: 2%;
  color: #fc7839;
  font-size: 3.5rem;
}

@media screen and (max-width: 470px) {
  padding-bottom: 5%;
}

@media screen and (max-width: 420px){
  font-size: 3.3rem;
  padding-top: 2%;
}
`;

export const SVGWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const Img = styled.img`
  width: 100%;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 900px) {
    max-width: 400px;
  }
`;

export const TextWrapper = styled.div`
  padding-top: 0;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;

  @media screen and (max-width: 968px){
    align-items: center;
  }

  @media screen and (max-width: 480px){
    padding-bottom: 0px;
  }
`;

export const HeroH1 = styled.h1`
  color: #fc7839;
  font-size: 4.5rem;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 20px;


  @media screen and (max-width: 900px) {
    font-size: 3.5rem;
    margin-left: 0;
    text-align: center;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;


export const HeroPBox = styled.div`
display: flex;
flex-direction: column;
max-width: 540px;
border-radius: 20px;

@media screen and (max-width: 900px) {
    margin-left: 20px;
    margin-right: 20px;
  }

  @media screen and (max-width: 700px) {
    margin-left: 0;
    margin-right: 0;
  }

  @media screen and (max-width: 700px) {
    margin-top: 0;
    margin-bottom: 0;
  }

`;

export const HeroP = styled.p`
  margin-top: 3%;
  margin-right: 40px;
  color: #414141;
  font-size: 1.1rem;
  text-align: left;
  max-width: 700px;

  @media screen and (max-width: 420px) and (max-height: 750px) {
    display: none;
}

  @media screen and (max-width: 768px) {
    font-size: 1rem;
    margin-left: 9%;
    margin-right: 9%;
  }

  @media screen and (max-width: 480px) {
    font-size: 15px;
  }
`;

export const HeroFooter = styled.div`
display: flex;
justify-content: center;
padding: 10% 0 6% 0;

@media screen and (max-width: 768px){
  padding: 0 0 10% 0;
}

`;

export const FooterImage = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

@media screen and (max-width: 480px) {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 0;
  margin-left: 0;
}

`;

export const FImg = styled.img`
width: 50%;
@media screen and (max-width: 480px) {
  width: 70%;
}
`;

export const MiniP = styled.p`
font-size: 1.1rem;
padding-bottom: 10px;
color: #5C5C5C;

@media screen and (max-width: 768px){
  display: none;
}
@media screen and (max-width: 420px) and (max-height: 750px) {
    display: flex;
    font-size: 0.9rem;
  color: #fc7839;
}
`;

export const UniImg = styled.img`
width: 45%;


@media screen and (max-width: 480px){
  display: none;
}
@media screen and (max-width: 420px) and (max-height: 750px) {
  display: flex;
  width: 80%;
}

`;










