import styled from 'styled-components';
import Background from '../../images/BoltBG.svg'
import MobileBackground from '../../images/BoltMobileBG.svg'

export const AlumniContainer = styled.div`
 background-color: #FFF7F3;
 background-image: url(${Background});
 height: auto;
 display: flex;
 justify-content: center;
 z-index: 1;
  padding-bottom: 10%;

  @media screen and (max-width: 480px){
  background-image: url(${MobileBackground});
  padding-bottom: 25%;
}

`;

export const AlumniContent = styled.div`
  z-index: 3;
  width: 90%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;

`;


export const AlumniH1 = styled.h1`
  color: #fc7839;
  font-size: 4.5rem;
  display: flex;
  align-items: center;
  text-align: center;
  padding-top: 8%;
  padding-bottom: 1%;

  @media screen and (max-width: 768px){
    font-size: 3rem;
  }
  
    @media screen and (max-width: 450px){
    font-size: 2.5rem;
    padding: 15% 0 12% 0;
}

`;

export const AlumniContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 768px){
    flex-direction: column;
  }
`;

export const AlumniPBox = styled.div`
display: flex;
min-height: 70vh;
min-width: 50%;
flex-direction: column;
justify-content: center;
align-items: left;
padding: 2%;
margin: 5% 5% 5% 0;
background-color: #fff;
border-radius: 10px;
z-index: 3;

@media screen and (max-width: 768px){
    min-width: 0;
    margin: 0;
    margin-bottom: 20%;
    padding: 3% 0;
}
`;

export const AlumniH2 = styled.h2`
font-size: 1.2rem;
text-align: center;
padding: 2% 0 2% 0;
margin-bottom: 5%;
margin-top: 5%;

@media screen and (max-width: 900px){
    padding: 2% 4%;
    
}
`;

export const AlumniP = styled.div`
  margin-bottom: 3%;
  margin-left: 5%;
  color: #000;
  font-size: 1rem;
  text-align: left;
  max-width: 700px;

  @media screen and (max-width: 900px){
    margin-right: 10%;
    margin-left: 10%;
    font-size: 1rem;
}

`;

export const QuoteSlider = styled.section`
  position: relative;
  display: flex;
  max-width: 90%;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 480%){
    max-width: 100%;
    min-width: 100%;
  }
  
`;

export const QuoteContentWrapper = styled.div`
 background-color: #fff;
 padding: 10% 0;
 min-height: 72vh;
 border-radius: 10px;
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;

 @media screen and (max-width: 450px){
  min-height: 50vh;
 }
`;

export const ScrollLeft = styled.div`
    position: absolute;
  top: 40%;
  left: 0px;
  font-size: 3rem;
  color: #fc7839;
  z-index: 10;
  cursor: pointer;
  user-select: none;

  &:hover {
    transform: scale(1.2);
    transition: all 0.3s ease-in-out;
  }
@media screen and (max-width: 450px){
  top: 20%
}

`;

export const ScrollRight = styled.div`
  position: absolute;
  top: 40%;
  right: 0px;
  font-size: 3rem;
  color: #fc7839;
  z-index: 10;
  cursor: pointer;
  user-select: none;

  @media screen and (max-width: 450px){
    top: 20%;
  }

  &:hover {
    transform: scale(1.2);
    transition: all 0.3s ease-in-out;
  }
`;

export const QuoteDescription = styled.p`
  font-size: 1rem;
  font-style: italic;
  padding: 5%;

  @media screen and (max-width: 450px){
    padding: 8%
  }
`;

export const AlumniName = styled.h3`
  font-size: 1rem;
  padding: 0 5%;
  text-align: center;

  @media screen and (max-width: 768px){
    padding: 5%;
  }
`;