import styled from "styled-components";
import Background from '../../images/BoltBG.svg'
import MobileBackground from '../../images/BoltMobileBG.svg'

export const AboutContainer = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #FFF7F3;
  background-image: url(${Background});
  padding-top: 20px;
  padding-bottom: 10%;

@media screen and (max-width: 480px){
  background-image: url(${MobileBackground});
  padding-bottom: 20%;
}
`;

export const AboutWrapper = styled.div`
  max-width: 1000px;
  width: 90%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  grid-gap: 3.5rem;
`;

export const AboutCard = styled.div`
  background: rgba(255, 255, 255, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
`;

export const TextBoxL = styled.div`
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-right: 30%;
  background-color: #fff;
  border-radius: 10px;
  z-index: 3;

  @media screen and (max-width: 900px) {
    margin-right: 20%;
  }

  @media screen and (max-width: 500px) {
    margin-right: 10%;
  }
`

export const TextBoxR = styled.div`
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: 30%;
  background-color: #fff;
  border-radius: 10px;

  @media screen and (max-width: 900px) {
    margin-left: 20%;
  }

  @media screen and (max-width: 500px) {
    margin-left: 10%;
  }
`


export const AboutH1 = styled.h1`
  font-size: 4.5rem;
  color: #fc7839;
  margin-bottom: 5%;
  padding: 5% 0px 0px 0px;

  @media screen and (max-width: 768px) {
    font-size: 3rem;
    text-align: center;
    padding: 5% 2% 0 2%;
  }

  @media screen and (max-width: 450px) {
    font-size: 2.5rem;
    margin-bottom: 10%;
    margin-top: 5%;
  }
`;

export const AboutP = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
  margin-left: 80px;
  margin-right: 80px;
  color: #000;
  font-size: 20px;
  text-align: left;
  max-width: 700px;

  @media screen and (max-width: 900px) {
    margin-right: 40px;
    margin-left: 40px;
    font-size: 16px;
  }

  @media screen and (max-width: 500px) {
    margin-right: 30px;
    margin-left: 30px;
    font-size: 1rem;
  }
`
