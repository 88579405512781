import React from 'react';
import {
  Container,
  AccessibilityWrap,
  Icon,
  HTLogo,
  Info,
  InfoH1,
  InfoH2,
  Text
} from './AccessibilityElements';

import MainLogo from '../../images/boltLogo.png';

const SignIn = () => {
  return (
    <>
      <Container>
        <AccessibilityWrap>
          <Icon to='/'>
            <HTLogo src={MainLogo} alt="Energy Linkage Logo" />
          </Icon>
            <Info>
              <InfoH1>Welcome, this is Aalto University's accessibility statement</InfoH1>
              <Text>This accessibility statement is about energylinkage.aalto.fi and has been created on 29.09.2023 The accessibility of this digital service has been evaluated by Google Lighthouse.</Text>
              <InfoH2>Accessibility status of the digital service</InfoH2>
              <Text>Meets all accessibility requirements</Text>
              <InfoH2>Did you notice an accessibility gap in our digital service? Let us know and we'll do our best to remedy it</InfoH2>
              <Text>By email: energy-linkage [at] aalto.fi</Text>
              <InfoH2>Authority</InfoH2>
              <Text>If you notice an accessibility problem on the site, please first provide feedback to us, the site administrators. It may take up to 14 days to respond. If you are not satisfied with the response you receive, or if you do not receive a response at all within two weeks, you can report the matter to the Regional State Administrative Agency of Southern Finland. The Southern Finland Regional State Administrative Agency's web page explains exactly how to report the matter and how it will be handled.</Text>
              <Text><b>Contact details of the supervisory authority</b> <br/>
              Regional State Administrative Agencie of Southern Finland <br/>
              Accessibility Supervision Unit <br/>
              www.webaccessibility.fi <br/>
              saavutettavuus@avi.fi <br/>
              Switchboard +358 295 016 000
              </Text>
            </Info>
        </AccessibilityWrap>
      </Container>
    </>
  );
};

export default SignIn;
